.wrapper {
  height: 100%;
  text-align: center;
  padding: 50px 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.canvas {
  width: 70%;
  border: 2px solid #c1c8c7;
}

.video {
  composes: canvas;
  display: none;
}

.button {
  margin-top: 20px;
}

.radioWrapper {
}

.radioInput {
  font-size: 14px;
}

.radioInput input:checked {
  background-color: #d49c6b;
  border-color: #d49c6b;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .canvas {
    width: 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}
