.roomId p {
  font-size: 14px;
  font-weight: 600;
  background-color: #d49c6b;
  color: #f6fafb;
  display: inline-block;
  padding: 0 10px;
}

.button {
  width: 100%;
  margin-top: 40px;
  position: relative;
  height: 78px;
}

.inputField {
  margin-top: 200px;
}

.form-control {
  font-size: 64px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c1c8c7;
  opacity: 1; /* Firefox */
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .inputField {
    margin-top: 150px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}
