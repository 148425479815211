.wrapper {
  background-color: #c1c8c7;
  min-height: calc(100vh - 60px - 70px);
}

.titleWrapper {
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 48px;
}

.info {
  font-size: 24px;
}

.chartWrapper {
  margin-bottom: 30px;
}

.handWrapper {
  margin-bottom: 30px;
}

.chatWrapper {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #f6fafb;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  height: 500px;
  overflow: auto;
}

.chatTitle {
  text-align: left;
  margin-bottom: 20px;
}

.chatList {
  list-style-type: none;
  padding: 0;
}

.message {
  padding: 5px 20px;
  border-radius: 20px;
  margin-bottom: 5px;
  width: 80%;
  word-break: break-word;
}

.receivedMessage {
  composes: message;
  background-color: #3f4042;
  color: #f6fafb;
  margin: 10px auto;
}

.receivedMessage a{
  color: rgb(82, 170, 225);
}