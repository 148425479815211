.title {
  text-align: center;
  font-size: 64px;
  margin: 10vh auto 15vh;
}

.button {
  width: 100%;
  height: 78px;
  margin-top: 40px;
  position: relative;
  background-color: #252b31;
}

.icon {
  margin-right: 10px;
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
}

.subtitle {
  text-align: center;
  font-size: 24px;
  margin: 100px 0;
}

.roomId {
  margin-left: 20px;
  font-size: 64px;
  color: #d49c6b;
  border-bottom: 2px solid #252b31;
  padding-bottom: 10px;
  font-weight: 500;
}

.shareWrapper {
  margin-top: 50px !important ;
}

.linkIndexWrapper {
  margin: 0 auto;
  border: 2px solid #d49c6b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #d49c6b;
}

.linkIndex {
  font-size: 24px;
  color: #f6fafb;
}

.linkDescription {
}

.linkWrapper {
  border: 2px solid #252b31;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkWrapper:hover {
  background-color: #d49c6b;
  border: 2px solid #252b31;
  color: #f6fafb;
}

.linkWrapper:hover .linkIcon {
  stroke-width: 3px;
}

.linkWrapper:hover .line {
  border-color: #252b31;
}

.linkIcon {
  margin-right: 20px;
  cursor: pointer;
}

.line {
  height: 100%;
  border-right: 2px solid #252b31;
}

.link {
  margin: 5px 15px;
  font-weight: 500;
  text-decoration: none;
  color: #252b31;
}

.link:hover {
  color: #252b31;
  text-decoration: underline 2px solid;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .title {
    font-size: 48px;
    margin: 60px auto 40px;
  }

  .linkDescription {
    width: 100%;
    margin-bottom: 20px;
  }

  .linkWrapper {
    margin-top: 20px !important ;
  }

  .linkIcon {
    display: none;
  }

  .line {
    display: none;
  }

  .link {
    font-size: 14px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}
