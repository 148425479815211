.wrapper {
  width: 100%;
  padding: 50px 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.subtitle {
  text-align: center;
  font-size: 12px;
  margin-bottom: 40px;
}

.chatWrapper {
  width: 100%;
  height: 500px;
  padding: 20px;
  background-color: white;
  border: 2px solid #c1c8c7;
  border-radius: 40px;
  overflow: auto;
}

.chatList {
  list-style-type: none;
  padding: 0;
}

.message {
  padding: 5px 20px;
  border-radius: 20px;
  margin-bottom: 5px;
  width: 40%;
  word-break: break-word;
}

.myMessage {
  composes: message;
  background-color: #d49c6b;
  margin-left: auto;
}

.receivedMessage {
  composes: message;
  background-color: #3f4042;
  color: #f6fafb;
  margin-right: auto;
}

.receivedMessage a{
  color: rgb(82, 170, 225);
}

.inputWrapper div {
  margin-top: 20px;
}

.input {
  width: 100%;
  font-size: 18px;
  padding: 20px;
  resize: none;
  border: none;
}

.input:focus {
  outline: none;
}

.button {
  width: 100%;
  height: 100%;
  margin: auto;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .chatWrapper {
    width: 100%;
    min-height: 60vh;
    max-height: 60vh;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}
