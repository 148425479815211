.wrapper {
  height: 100%;
  text-align: center;
  padding: 50px 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.emoji {
  font-size: 96px;
  font-weight: normal;
}

.sliderWrapper {
  position: relative;
}

.labelLeft {
  position: absolute;
  right: 0;
}

.labelRight {
  position: absolute;
  left: 0;
}

.slider {
  position: absolute;
  top: 30px;
}

.tooltip {
}
