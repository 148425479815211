.wrapper {
  color: #f6fafb;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  padding: 10%;
  position: absolute;
  z-index: 20;
  background-color: #252b31;
  overflow-y: scroll;
}

.title {
  font-size: 64px;
  margin-bottom: 100px;
  line-height: 150%;
}

.highlight {
  background-color: #d49c6b;
  font-weight: 600;
  text-decoration: underline 4px;
}

.orange {
  color: #d49c6b;
}

.content {
  font-weight: 300;
  font-size: 18px;
  line-height: 180%;
}

.line {
  border-bottom: 1px solid #f6fafb;
  margin: 20px auto;
}

.link {
  margin-left: 3px;
  text-decoration: underline 1px;
  color: #f6fafb;
}

.link:hover {
  color: #d49c6b;
}

.noteWrapper {
  position: relative;
}

.note {
  position: absolute;
  left: 0;
  font-size: 14px;
}

.github {
  height: 25px;
  position: absolute;
  right: 0;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}
