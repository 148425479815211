.wrapper {
  margin-top: 20px;
  background-color: #f6fafb;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.title {
  text-align: left;
}

.canvas {
  width: 50%;
  margin: 20px auto;
  border: 2px solid #c1c8c7;
}
