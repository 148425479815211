@import url(https://fonts.googleapis.com/css2?family=Literata:wght@200;300;400;500;600;700&display=swap);
.Header_wrapper__8u6DX {
  padding: 20px;
  transition: background-color 500ms ease-in-out;
}

.Header_wrapperGrey__GrHTy {
  background-color: #c1c8c7;
}

.Header_wrapperBlue__24P5d {
  background-color: #252b31;
}

.Header_helpWrapper__1fGrU {
  display: inline-block;
  border: 2px solid #252b31;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}

.Header_helpWrapper__1fGrU:hover {
  background-color: #252b31;
}

.Header_helpWrapper__1fGrU:hover .Header_help__1-StK {
  color: #f6fafb;
}

.Header_help__1-StK {
  color: #252b31;
  font-size: 18px;
}

.Header_icon__2X2E3 {
  cursor: pointer;
}

.Header_close__o59Um {
  cursor: pointer;
  stroke: #f6fafb;
}

.Header_close__o59Um:hover {
  fill: #f6fafb;
  stroke: #252b31;
}

.Footer_wrapper__3EW7g {
  width: 100vw;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  background-color: #f6fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.Footer_adminWrapper__HqKm_ {
  background-color: #c1c8c7;
}

.Footer_link__1NGbN {
  margin-left: 5px;
  -webkit-text-decoration: underline 1px;
          text-decoration: underline 1px;
  color: #252b31;
}

.Footer_link__1NGbN:hover {
  color: #d49c6b;
}

.Layout_wrapper__qVwAl {
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #f6fafb;
  color: #252b31;
  padding-bottom: 60px;
}

.Help_wrapper__1YzCO {
  color: #f6fafb;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  padding: 10%;
  position: absolute;
  z-index: 20;
  background-color: #252b31;
  overflow-y: scroll;
}

.Help_title__3J5OI {
  font-size: 64px;
  margin-bottom: 100px;
  line-height: 150%;
}

.Help_highlight__Flc2K {
  background-color: #d49c6b;
  font-weight: 600;
  -webkit-text-decoration: underline 4px;
          text-decoration: underline 4px;
}

.Help_orange__bHz7u {
  color: #d49c6b;
}

.Help_content__EyKed {
  font-weight: 300;
  font-size: 18px;
  line-height: 180%;
}

.Help_line__3sL4I {
  border-bottom: 1px solid #f6fafb;
  margin: 20px auto;
}

.Help_link__252Nh {
  margin-left: 3px;
  -webkit-text-decoration: underline 1px;
          text-decoration: underline 1px;
  color: #f6fafb;
}

.Help_link__252Nh:hover {
  color: #d49c6b;
}

.Help_noteWrapper__3e9jO {
  position: relative;
}

.Help_note__D-A6U {
  position: absolute;
  left: 0;
  font-size: 14px;
}

.Help_github__32__N {
  height: 25px;
  position: absolute;
  right: 0;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .Help_title__3J5OI {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}

.fade_enter__1WTi0 {
  opacity: 0;
}

.fade_enterActive__22nhI {
  opacity: 1;
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fade_exit__3c1G4 {
  opacity: 1;
}

.fade_exitActive__5Qm9T {
  opacity: 0;
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.Home_title__3h164 {
  text-align: center;
  font-size: 64px;
  margin: 10vh auto 15vh;
}

.Home_button__3_K0L {
  width: 100%;
  height: 78px;
  margin-top: 40px;
  position: relative;
  background-color: #252b31;
}

.Home_icon__E29n1 {
  margin-right: 10px;
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
}

.Home_subtitle__3oOPC {
  text-align: center;
  font-size: 24px;
  margin: 100px 0;
}

.Home_roomId__kByFp {
  margin-left: 20px;
  font-size: 64px;
  color: #d49c6b;
  border-bottom: 2px solid #252b31;
  padding-bottom: 10px;
  font-weight: 500;
}

.Home_shareWrapper__oDVa0 {
  margin-top: 50px !important ;
}

.Home_linkIndexWrapper__uF1RR {
  margin: 0 auto;
  border: 2px solid #d49c6b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #d49c6b;
}

.Home_linkIndex__2S9FU {
  font-size: 24px;
  color: #f6fafb;
}

.Home_linkDescription__2YeUu {
}

.Home_linkWrapper__1GB1b {
  border: 2px solid #252b31;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Home_linkWrapper__1GB1b:hover {
  background-color: #d49c6b;
  border: 2px solid #252b31;
  color: #f6fafb;
}

.Home_linkWrapper__1GB1b:hover .Home_linkIcon__S2CtP {
  stroke-width: 3px;
}

.Home_linkWrapper__1GB1b:hover .Home_line__9OLps {
  border-color: #252b31;
}

.Home_linkIcon__S2CtP {
  margin-right: 20px;
  cursor: pointer;
}

.Home_line__9OLps {
  height: 100%;
  border-right: 2px solid #252b31;
}

.Home_link__3seiP {
  margin: 5px 15px;
  font-weight: 500;
  text-decoration: none;
  color: #252b31;
}

.Home_link__3seiP:hover {
  color: #252b31;
  -webkit-text-decoration: underline 2px solid;
          text-decoration: underline 2px solid;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .Home_title__3h164 {
    font-size: 48px;
    margin: 60px auto 40px;
  }

  .Home_linkDescription__2YeUu {
    width: 100%;
    margin-bottom: 20px;
  }

  .Home_linkWrapper__1GB1b {
    margin-top: 20px !important ;
  }

  .Home_linkIcon__S2CtP {
    display: none;
  }

  .Home_line__9OLps {
    display: none;
  }

  .Home_link__3seiP {
    font-size: 14px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}

.Room_roomId__3Tqu8 p {
  font-size: 14px;
  font-weight: 600;
  background-color: #d49c6b;
  color: #f6fafb;
  display: inline-block;
  padding: 0 10px;
}

.Room_button__3bu3E {
  width: 100%;
  margin-top: 40px;
  position: relative;
  height: 78px;
}

.Room_inputField___D0Eh {
  margin-top: 200px;
}

.Room_form-control__Dc9T5 {
  font-size: 64px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c1c8c7;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c1c8c7;
  opacity: 1; /* Firefox */
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .Room_inputField___D0Eh {
    margin-top: 150px;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}

.MoodSlider_wrapper__2U-xg {
  height: 100%;
  text-align: center;
  padding: 50px 20px;
}

.MoodSlider_title__EeGgx {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.MoodSlider_emoji__3EnOU {
  font-size: 96px;
  font-weight: normal;
}

.MoodSlider_sliderWrapper__3ROyC {
  position: relative;
}

.MoodSlider_labelLeft__2bvbL {
  position: absolute;
  right: 0;
}

.MoodSlider_labelRight__2sjWV {
  position: absolute;
  left: 0;
}

.MoodSlider_slider__3K-H7 {
  position: absolute;
  top: 30px;
}

.MoodSlider_tooltip__3cq16 {
}

.HandTrack_wrapper__2H8FY {
  height: 100%;
  text-align: center;
  padding: 50px 20px;
}

.HandTrack_title__2DH_0 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.HandTrack_canvas__2V592 {
  width: 70%;
  border: 2px solid #c1c8c7;
}

.HandTrack_video__3kLA8 {
  display: none;
}

.HandTrack_button__1oZcA {
  margin-top: 20px;
}

.HandTrack_radioWrapper__1iPcm {
}

.HandTrack_radioInput__1A_m3 {
  font-size: 14px;
}

.HandTrack_radioInput__1A_m3 input:checked {
  background-color: #d49c6b;
  border-color: #d49c6b;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .HandTrack_canvas__2V592 {
    width: 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}

.Chat_wrapper__CHisv {
  width: 100%;
  padding: 50px 20px;
}

.Chat_title__pmpCz {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.Chat_subtitle__1WfYJ {
  text-align: center;
  font-size: 12px;
  margin-bottom: 40px;
}

.Chat_chatWrapper__3ZyBv {
  width: 100%;
  height: 500px;
  padding: 20px;
  background-color: white;
  border: 2px solid #c1c8c7;
  border-radius: 40px;
  overflow: auto;
}

.Chat_chatList__2Mf8W {
  list-style-type: none;
  padding: 0;
}

.Chat_message__2fHn9 {
  padding: 5px 20px;
  border-radius: 20px;
  margin-bottom: 5px;
  width: 40%;
  word-break: break-word;
}

.Chat_myMessage__3g0dh {
  background-color: #d49c6b;
  margin-left: auto;
}

.Chat_receivedMessage__pC3hX {
  background-color: #3f4042;
  color: #f6fafb;
  margin-right: auto;
}

.Chat_receivedMessage__pC3hX a{
  color: rgb(82, 170, 225);
}

.Chat_inputWrapper__X7QlQ div {
  margin-top: 20px;
}

.Chat_input__18cXx {
  width: 100%;
  font-size: 18px;
  padding: 20px;
  resize: none;
  border: none;
}

.Chat_input__18cXx:focus {
  outline: none;
}

.Chat_button__35q97 {
  width: 100%;
  height: 100%;
  margin: auto;
}

/**************
Responsive
**************/
@media screen and (max-width: 480px) {
  /*　Smartphone portrait */
  .Chat_chatWrapper__3ZyBv {
    width: 100%;
    min-height: 60vh;
    max-height: 60vh;
  }
}

@media screen and (min-width: 480px) and (max-width: 896px) {
  /*　for iPad portrait and Smartphone landscape 　*/
}

.Admin_wrapper__2mv_q {
  background-color: #c1c8c7;
  min-height: calc(100vh - 60px - 70px);
}

.Admin_titleWrapper__1NLZX {
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.Admin_title__2TOc4 {
  font-size: 48px;
}

.Admin_info__p8EGg {
  font-size: 24px;
}

.Admin_chartWrapper__2CVZc {
  margin-bottom: 30px;
}

.Admin_handWrapper__5BXU7 {
  margin-bottom: 30px;
}

.Admin_chatWrapper__2QVOV {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #f6fafb;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  height: 500px;
  overflow: auto;
}

.Admin_chatTitle__3zEYt {
  text-align: left;
  margin-bottom: 20px;
}

.Admin_chatList__kb48C {
  list-style-type: none;
  padding: 0;
}

.Admin_message__1Rro5 {
  padding: 5px 20px;
  border-radius: 20px;
  margin-bottom: 5px;
  width: 80%;
  word-break: break-word;
}

.Admin_receivedMessage__OTZsx {
  background-color: #3f4042;
  color: #f6fafb;
  margin: 10px auto;
}

.Admin_receivedMessage__OTZsx a{
  color: rgb(82, 170, 225);
}
.BarChart_wrapper__xPBXz {
  background-color: #f6fafb;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
}

.BarChart_title__1lGml {
}

.BarChart_average__3272q {
}

.BarChart_chart__2ry73 {
}

.HandPosition_wrapper__1YpGh {
  margin-top: 20px;
  background-color: #f6fafb;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.HandPosition_title__h8glR {
  text-align: left;
}

.HandPosition_canvas__366Ji {
  width: 50%;
  margin: 20px auto;
  border: 2px solid #c1c8c7;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Literata", serif;
  font-weight: 400;
}

