.wrapper {
  padding: 20px;
  transition: background-color 500ms ease-in-out;
}

.wrapperGrey {
  composes: wrapper;
  background-color: #c1c8c7;
}

.wrapperBlue {
  composes: wrapper;
  background-color: #252b31;
}

.helpWrapper {
  display: inline-block;
  border: 2px solid #252b31;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}

.helpWrapper:hover {
  background-color: #252b31;
}

.helpWrapper:hover .help {
  color: #f6fafb;
}

.help {
  color: #252b31;
  font-size: 18px;
}

.icon {
  cursor: pointer;
}

.close {
  cursor: pointer;
  stroke: #f6fafb;
}

.close:hover {
  fill: #f6fafb;
  stroke: #252b31;
}
